import type { FC } from "react"
import React from "react"
import type { LinkProps as ChakraLinkProps } from "@chakra-ui/react"
import { Link as ChakraLink } from "@chakra-ui/react"
import NextLink from "next/link"

type Props = Omit<ChakraLinkProps, "as">

const Link: FC<Props> = ({ children, ...rest }) => (
  <ChakraLink {...rest} as={NextLink}>
    {children}
  </ChakraLink>
)

export default Link
