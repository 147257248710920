import type { LinkProps } from "@chakra-ui/react"
import { Button, Icon, IconButton, Stack, Text } from "@chakra-ui/react"
import type { FC, LegacyRef } from "react"

import Link from "common/components/Link"
import { ROUTES } from "common/constants/routes"
import TallyBlack from "ui/components/icons/tally/TallyBlack"
import { useDevice } from "common/hooks/useDevice"
import { MagnifyingGlass } from "ui/components/icons/font-awesome/MagnifyingGlass"
import { Wallet } from "ui/components/icons/font-awesome/Wallet"
import HomeIcon from "ui/components/icons/HomeIcon"
import { Plus } from "ui/components/icons/font-awesome/Plus"
import { Books } from "ui/components/icons/font-awesome/Books"
import { Volume } from "ui/components/icons/font-awesome/Volume"
import { Code } from "ui/components/icons/font-awesome/Code"
import { Comments } from "ui/components/icons/font-awesome/Comments"
import { Bars } from "ui/components/icons/font-awesome/Bars"

type Disclosure = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

type Props = {
  includeSearchButton?: boolean
  searchModalRef?: LegacyRef<HTMLButtonElement>
  searchDaosToastDisclousure?: Disclosure
}
export const HeaderTopLeftCommon: FC<Props> = ({
  includeSearchButton = false,
  searchModalRef,
  searchDaosToastDisclousure,
}) => {
  const { onLargeDevice } = useDevice()

  return (
    <Stack align="center" direction="row" h="36px">
      <Link className="no-underline" h="36px" href={ROUTES.home()}>
        <Icon as={TallyBlack} h="full" w={{ base: 24, lg: 24 }} />
      </Link>

      {includeSearchButton && searchModalRef && searchDaosToastDisclousure ? (
        <Button
          ref={searchModalRef}
          _focusVisible={{
            boxShadow: "none",
            borderColor: "transparent",
            outline: "3px solid hsla(216, 12%, 84%, 1)",
            outlineOffset: "2px",
          }}
          aria-label="Search for a DAO"
          borderColor="transparent"
          data-qa="mainlayout-searchDAOs"
          display={onLargeDevice}
          variant="secondary"
          onClick={() => searchDaosToastDisclousure.onOpen()}
        >
          <Icon as={MagnifyingGlass} fill="gray.600" h={6} mr={2} w={4} />
          <Text color="gray.600" fontWeight="medium" textStyle="md">
            Search DAOs
          </Text>
        </Button>
      ) : null}
    </Stack>
  )
}

export const HeaderLinksCommon: FC<LinkProps> = ({ ...linkProps }) => {
  const { onLittleDevice } = useDevice()

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      p={{ base: 4, md: 0 }}
      spacing={6}
    >
      <Link
        className="no-underline"
        color="gray.700"
        data-qa="mainlayoutheader-btn-home"
        display={onLittleDevice}
        fontWeight="medium"
        href={ROUTES.home()}
        lineHeight="24px"
        {...linkProps}
      >
        <Stack isInline align="center" spacing={2}>
          <Icon as={HomeIcon} display={onLittleDevice} h={4} w={4} />
          <Text>Tally home</Text>
        </Stack>
      </Link>
      <Link
        className="no-underline"
        color="gray.700"
        data-qa="mainlayoutheader-btn-explore"
        display={onLittleDevice}
        fontWeight="medium"
        href={ROUTES.explore()}
        lineHeight="24px"
        {...linkProps}
      >
        <Stack isInline align="center" spacing={2}>
          <Icon as={MagnifyingGlass} display={onLittleDevice} h={4} w={4} />
          <Text>Explore DAOs</Text>
        </Stack>
      </Link>
      <Link
        className="no-underline"
        color="gray.700"
        data-qa="mainlayoutheader-btn-addadao"
        fontWeight="medium"
        href={ROUTES.getStarted.index()}
        lineHeight="24px"
        {...linkProps}
      >
        <Stack isInline align="center" spacing={2}>
          <Icon as={Plus} display={onLittleDevice} h={4} w={4} />
          <Text>Add a DAO</Text>
        </Stack>
      </Link>
      <Link
        className="no-underline"
        color="gray.700"
        display={onLittleDevice}
        fontWeight="medium"
        href="#"
        id="open_web_chat"
        lineHeight="24px"
        {...linkProps}
      >
        <Stack isInline align="center" spacing={2}>
          <Icon as={Comments} display={onLittleDevice} h={4} w={4} />
          <Text>Chat with Support</Text>
        </Stack>
      </Link>
      <Link
        className="no-underline"
        color="gray.700"
        fontWeight="medium"
        href={ROUTES.resources()}
        lineHeight="24px"
        {...linkProps}
      >
        <Stack isInline align="center" spacing={2}>
          <Icon as={Books} display={onLittleDevice} h={4} w={4} />
          <Text>Resources</Text>
        </Stack>
      </Link>
      <Link
        className="no-underline"
        color="gray.700"
        fontWeight="medium"
        href={ROUTES.media()}
        lineHeight="24px"
        {...linkProps}
      >
        <Stack isInline align="center" spacing={2}>
          <Icon as={Volume} display={onLittleDevice} h={4} w={4} />
          <Text>Media</Text>
        </Stack>
      </Link>
      <Link
        className="no-underline"
        color="gray.700"
        fontWeight="medium"
        href={ROUTES.developers()}
        lineHeight="24px"
        {...linkProps}
      >
        <Stack isInline align="center" spacing={2}>
          <Icon as={Code} display={onLittleDevice} h={4} w={4} />
          <Text>Developers</Text>
        </Stack>
      </Link>
    </Stack>
  )
}

type HeaderTopRightCommonProps = {
  searchDrawerRef: any
  searchDaosDrawerDisclousure: any
  userMenuRef: LegacyRef<HTMLButtonElement>
  userMenuDisclousure: Disclosure
  tallyMenuRef: LegacyRef<HTMLButtonElement>
  tallyMenuDisclousure: Disclosure
}
export const HeaderTopRightCommon: FC<HeaderTopRightCommonProps> = ({
  searchDrawerRef,
  searchDaosDrawerDisclousure,
  userMenuRef,
  userMenuDisclousure,
  tallyMenuRef,
  tallyMenuDisclousure,
}) => {
  return (
    <>
      <IconButton
        ref={searchDrawerRef}
        _focusVisible={{
          boxShadow: "none",
          borderColor: "transparent",
          outline: "3px solid hsla(216, 12%, 84%, 1)",
          outlineOffset: "2px",
        }}
        aria-label="Go to home page"
        bg="white"
        icon={<Icon as={MagnifyingGlass} fill="neutral.500" h={4} w={4} />}
        p={1}
        onClick={() => searchDaosDrawerDisclousure.onOpen()}
      />

      <IconButton
        ref={userMenuRef}
        _focusVisible={{
          boxShadow: "none",
          borderColor: "transparent",
          outline: "3px solid hsla(216, 12%, 84%, 1)",
          outlineOffset: "2px",
        }}
        aria-label="Open user menu"
        bg="white"
        icon={<Icon as={Wallet} color="gray.700" h={5} w={5} />}
        p={1}
        onClick={() => userMenuDisclousure.onOpen()}
      />

      <IconButton
        ref={tallyMenuRef}
        _focusVisible={{
          boxShadow: "none",
          borderColor: "transparent",
          outline: "3px solid hsla(216, 12%, 84%, 1)",
          outlineOffset: "2px",
        }}
        aria-label="Open Tally menu"
        bg="white"
        icon={<Icon as={Bars} color="gray.700" h={5} w={5} />}
        p={1}
        onClick={() => tallyMenuDisclousure.onOpen()}
      />
    </>
  )
}
