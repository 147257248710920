import type { FC } from "react"
import React from "react"
import type { ButtonProps } from "@chakra-ui/react"
import {
  useClipboard,
  Stack,
  IconButton,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Icon,
} from "@chakra-ui/react"
import makeBlockie from "ethereum-blockies-base64"
import { useAccount } from "wagmi"

import { shortenIfAddress } from "web3/helpers/address"
import UserAvatar from "common/components/UserAvatar"
import { shortString } from "common/helpers/string"
import { Copy } from "ui/components/icons/font-awesome/Copy"
import { useModals } from "common/hooks/useModals"
import { useRouter } from "common/hooks/useRouter"
import { useToast } from "common/hooks/useToast"
import { useConnectorIcon } from "web3/hooks/useConnectorIcon"

const HeaderAddressesButton: FC<
  {
    onOpen: () => void
    signerAddress: string
    redirectTo?: string
  } & ButtonProps
> = ({ onOpen, signerAddress, redirectTo, ...buttonProps }) => {
  const connectorIcon = useConnectorIcon()
  const { address } = useAccount()
  const { isOpen, onClose, onOpen: onOpenConnectedWallet } = useDisclosure()

  const handleSwitchWallet = async (): Promise<void> => {
    if (address) {
      onOpenConnectedWallet()
    } else {
      onOpen()
    }
  }

  return (
    <Button
      _focusVisible={{
        boxShadow: "none",
        borderColor: "transparent",
        outline: "3px solid hsla(216, 12%, 84%, 1)",
        outlineOffset: "2px",
      }}
      border="gray.300"
      leftIcon={<Icon as={connectorIcon} h={6} w={6} />}
      variant="secondary"
      onClick={handleSwitchWallet}
      {...buttonProps}
      data-qa="connect-wallet-button"
    >
      <Text color="gray.700" data-qa="connect-wallet-button-text">
        {shortenIfAddress(signerAddress)}
      </Text>
      <ConnectedWalletModal
        isOpen={isOpen}
        redirectTo={redirectTo}
        onClose={onClose}
      />
    </Button>
  )
}

function ConnectedWalletModal({
  isOpen,
  onClose,
  redirectTo,
}: {
  isOpen: boolean
  onClose: () => void
  redirectTo?: string
}) {
  const { address = "", connector } = useAccount()
  const { walletSelection } = useModals()
  const { reload, push } = useRouter()
  const { onCopy } = useClipboard(address)
  const { toast } = useToast()

  if (!address) return null

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent maxWidth={408} top={{ base: "unset", lg: "120px" }}>
        <ModalHeader
          borderBottomColor="gray.100"
          borderBottomWidth={1}
          p={4}
          textStyle="h5"
        >
          Connected
        </ModalHeader>
        <ModalCloseButton right={4} top={4} />
        <ModalBody p={4}>
          <Stack alignItems="center" spacing={6}>
            <UserAvatar
              address={address}
              size="xl"
              src={makeBlockie(address)}
            />
            <Stack align="center" direction="row" spacing={1}>
              <Text fontWeight="medium" lineHeight="24px">
                {shortString(address)}
              </Text>
              <IconButton
                aria-label="Copy address"
                bg="white"
                h={8}
                icon={<Icon as={Copy} />}
                minW={8}
                onClick={() => {
                  onCopy()

                  toast({
                    status: "success",
                    title: "Copied address",
                  })
                }}
              />
            </Stack>
            <Button
              variant="secondary"
              onClick={() => {
                if (!connector) return

                connector.disconnect()
                setTimeout(() => {
                  walletSelection.onClose()

                  if (redirectTo) {
                    push(redirectTo).then(() => reload())
                  } else {
                    reload()
                  }
                }, 1000)
              }}
            >
              Disconnect
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default HeaderAddressesButton
