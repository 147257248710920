import type { FC, ReactNode } from "react"
import React, { useState, useEffect, useMemo } from "react"
import type { ButtonProps } from "@chakra-ui/react"
import {
  Flex,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Skeleton,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Divider,
  Spacer,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { BigNumber } from "@ethersproject/bignumber"
import capitalize from "lodash.capitalize"

import { EXTERNAL_ROUTES, API_ROUTES } from "common/constants/routes"
import type {
  GovernanceModuleInformationQuery,
  AccountId,
  Chain as ConstChain,
} from "query/graphql"
import { GovernorType, GovernorKind } from "query/graphql"
import { getAccountIdParams } from "web3/helpers/accountId"
import ContractAddress from "organization/components/ContractAddress"
import Link from "common/components/Link"
import { getWeightLabel, stringToBigNumber } from "common/helpers/bignumber"
import { getDateFromBlocks } from "common/helpers/date"
import type { ArrayElement } from "common/types/array"
import { fetcher } from "common/helpers/fetcher"
import { getParameterDatesLabel } from "governance/helpers/governance"
import NetworkTag from "common/components/NetworkTag"
import CommonTag from "common/components/CommonTag"
import CrosshairIcon from "ui/components/icons/CrosshairIcon"
import SlidersUpIcon from "ui/components/icons/SlidersUpIcon"
import FileInvoiceIcon from "ui/components/icons/FileInvoiceIcon"
import AlignLeftIcon from "ui/components/icons/AlignLeftIcon"
import { useDevice } from "common/hooks/useDevice"
import { getAssetIdParams } from "web3/helpers/assetId"
import type { ChainId } from "web3/constants/chains"
import chains from "web3/constants/chains"
import { subString } from "common/helpers/string"

type Values = {
  isLoading: boolean
  governanceModules: GovernanceModuleInformationQuery[] | null
}

type UseGovernancesModuleInformationProps = {
  governanceIds: AccountId[]
}

type NavigationOptionProps = {
  children: ReactNode
  onClick?: () => void
}
const NavigationOption: FC<NavigationOptionProps & ButtonProps> = ({
  children,
  onClick,
  ...buttonProps
}) => {
  return (
    <Box as="li" listStyleType="none" role="none" width="100%">
      <Button
        bg="white"
        h="full"
        rounded="md"
        w="full"
        onClick={onClick}
        {...buttonProps}
      >
        <Stack align="center" direction="row" justify="flex-start" w="full">
          <Text
            color="neutral.700"
            fontSize="16px"
            fontWeight="medium"
            textAlign="left"
            width="100%"
          >
            {children}
          </Text>
        </Stack>
      </Button>
    </Box>
  )
}

export const useAllGovernanceModuleInformations = ({
  governanceIds,
}: UseGovernancesModuleInformationProps): Values => {
  const [governanceModules, setGovernanceModules] = useState<
    GovernanceModuleInformationQuery[] | null
  >(null)
  const [isLoading, setIsLoading] = useState(false)

  const fetchGovernanceModuleInformation = async (governanceId: AccountId) => {
    const response = await fetcher.rest<any | undefined>({
      endpoint: API_ROUTES.governanceModuleInformation(governanceId),
      error: {
        warning: {
          isSilent: true,
        },
      },
      method: "GET",
    })

    return response
  }

  useEffect(() => {
    const fetchAllGovernanceModules = async () => {
      setIsLoading(true)

      const modules = await Promise.all(
        governanceIds.map(async (id: AccountId) => {
          return fetchGovernanceModuleInformation(id)
        }),
      )

      // Filter out Multiother governors
      const filterModules = modules
        ? modules.filter(
            (module) => module.governor.kind !== GovernorKind.Multiother,
          )
        : []

      setGovernanceModules(filterModules)
      setIsLoading(false)
    }

    fetchAllGovernanceModules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [governanceIds?.[0]])

  return {
    governanceModules,
    isLoading,
  }
}

type Chains = GovernanceModuleInformationQuery["chains"]
type Chain = ArrayElement<Chains>

type ContractsModalProps = {
  isOpen: boolean
  governanceIds: string[]
  onClose: () => void
}

function ContractsModal({
  isOpen,
  governanceIds,
  onClose,
}: ContractsModalProps) {
  // Sort governors by mainnet chains first
  const sortGovernanceIds = governanceIds
    .map((govId) => {
      const { chainId } = getAccountIdParams(govId)
      const chain = chains[chainId as ChainId]

      return { govId, chain }
    })
    // Remove governors from deprecated chains
    .filter(
      (govChain) => !govChain.chain.name.toLowerCase().includes("deprecated"),
    )
    .sort(
      (
        chainGovA: { govId: string; chain: ConstChain },
        chainGovB: { govId: string; chain: ConstChain },
      ) => {
        if (!chainGovA) return 1
        if (!chainGovB) return 1

        if (!chainGovA.chain.isTestnet && chainGovB.chain.isTestnet) return -1
        if (chainGovA.chain.isTestnet && !chainGovB.chain.isTestnet) return 1

        return 0
      },
    )
    .map((chainGov) => chainGov?.govId)

  const { onLargeDevice, onLittleDevice } = useDevice()
  const { governanceModules, isLoading } = useAllGovernanceModuleInformations({
    governanceIds: sortGovernanceIds,
  })

  const governanceNames = useMemo(() => {
    if (governanceModules)
      return governanceModules.map((module) => module.governor.name)

    return null
  }, [governanceModules])

  const [selectedGovernorIndex, setSelectedGovernorIndex] = useState(0)

  const handleSelectGovernor = (name: string) => {
    if (governanceModules != null) {
      const index = governanceModules.findIndex(
        (module) => module.governor.name === name,
      )
      setSelectedGovernorIndex(index)
    }
  }

  const displayGovernorsButtons = () => {
    if (isLoading)
      return Array.from({ length: 2 }).map((_, index) => (
        <Skeleton key={`gov-btn-${index}`} h="40px" w="full" />
      ))

    return (
      <>
        <Box display={onLittleDevice}>
          <Menu>
            <MenuButton
              _active={{ bgColor: "white" }}
              _empty={{ bgColor: "white" }}
              _focus={{ bgColor: "white" }}
              as={Button}
              bgColor="white"
              borderWidth="1px"
              height="50px"
              m={0}
              rightIcon={<ChevronDownIcon />}
              width="100%"
            >
              <NavigationOption>
                {governanceNames
                  ? subString(governanceNames[selectedGovernorIndex], 30)
                  : "Governor"}
              </NavigationOption>
            </MenuButton>

            <MenuList mt={-2} mx={4} rootProps={{ width: "100%" }} zIndex={500}>
              {governanceNames != null
                ? governanceNames.map((name) => {
                    return (
                      <MenuItem
                        key={name}
                        bgColor="white"
                        height="50px"
                        isDisabled={
                          governanceModules &&
                          name ===
                            governanceModules[selectedGovernorIndex].governor
                              .name
                            ? true
                            : false
                        }
                        width="100%"
                        onClick={() => handleSelectGovernor(name)}
                      >
                        <NavigationOption width="100%">{name}</NavigationOption>
                      </MenuItem>
                    )
                  })
                : null}
            </MenuList>
          </Menu>
        </Box>

        <Stack display={onLargeDevice}>
          {governanceNames &&
            governanceNames.map((name) => (
              <Button
                key={name}
                borderColor={
                  governanceModules &&
                  name ===
                    governanceModules[selectedGovernorIndex].governor.name
                    ? "gray.600"
                    : undefined
                }
                borderWidth={
                  governanceModules &&
                  name ===
                    governanceModules[selectedGovernorIndex].governor.name
                    ? "2px"
                    : undefined
                }
                variant="secondary"
                onClick={() => handleSelectGovernor(name)}
              >
                {subString(name, 22)}
              </Button>
            ))}
        </Stack>
      </>
    )
  }

  const displayForAave = useMemo(() => {
    if (governanceModules && governanceModules.length > 0) {
      const { governor: governance } = governanceModules[0]
      const { contracts } = governance
      const { governor } = contracts
      const { type } = governor

      if (type === GovernorType.Aave) return true
    }

    return false
  }, [governanceModules])

  const displayGovernorSwitchButtons =
    (governanceNames && governanceNames?.length > 1) || displayForAave

  const displayBodyContent = () => {
    if (displayForAave && governanceModules) {
      return (
        <DisplayForAave governanceModuleInformation={governanceModules[0]} />
      )
    }

    return (
      <Stack direction={{ base: "column", md: "row" }}>
        {displayGovernorSwitchButtons ? (
          <Stack
            direction="column"
            pb={{ base: 4, md: 0 }}
            pr={{ base: 0, md: 4 }}
            width={{ base: "100%", md: "30%" }}
          >
            <Text fontWeight="bold" textStyle="sm">
              <CrosshairIcon mr={1} />
              Governors
            </Text>
            {displayGovernorsButtons()}
          </Stack>
        ) : null}

        <Stack
          alignSelf="flex-start"
          spacing={3}
          width={{
            base: "100%",
            md: displayGovernorSwitchButtons ? "70%" : "100%",
          }}
        >
          {isLoading ? (
            <>
              <Stack mb={2} px={6} py={4} spacing={4}>
                <Skeleton h="400" w="100%" />
              </Stack>
            </>
          ) : governanceModules ? (
            <GovernanceInformationSection
              key={`gov_${selectedGovernorIndex}`}
              displayGovernanceName={false}
              governanceModuleInformation={
                governanceModules[selectedGovernorIndex]
              }
              isLoading={isLoading}
            />
          ) : null}
        </Stack>
      </Stack>
    )
  }

  return (
    <Modal closeOnOverlayClick isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent
        maxWidth={displayGovernorSwitchButtons ? "850px" : "600px"}
        mb={{ base: 4, sm: 6, md: 8 }}
        mt={{ sm: "0px" }}
        mx={{ base: "0px", md: "auto" }}
        top={{ base: "0px", md: "120px" }}
      >
        <ModalHeader
          borderBottomColor="gray.100"
          borderBottomWidth={1}
          p={4}
          textStyle="h5"
        >
          Contracts and parameters
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody overflowY="auto" px={4} py={4}>
          {displayBodyContent()}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ContractsModal

enum ExecutorType {
  ShortExecutor = "Short Executor",
  LongExecutor = "Long Executor",
}

type DisplayForAaveProps = {
  governanceModuleInformation: any
}
const DisplayForAave: FC<DisplayForAaveProps> = ({
  governanceModuleInformation,
}) => {
  const { onLittleDevice, onLargeDevice } = useDevice()
  const chainId = governanceModuleInformation?.governor.chainId
  const chains = governanceModuleInformation?.chains

  const chain = chains
    ? chains.find((chain: Chain) => chain && chain.id === chainId)
    : null

  const [selectedExecutor, handleSelectExecutor] = useState(
    ExecutorType.ShortExecutor,
  )

  if (!governanceModuleInformation) return null

  const displayExecutorsButtons = () => {
    return (
      <>
        <Box display={onLittleDevice}>
          <Menu>
            <MenuButton
              _active={{ bgColor: "white" }}
              _empty={{ bgColor: "white" }}
              _focus={{ bgColor: "white" }}
              as={Button}
              bgColor="white"
              borderWidth="1px"
              height="50px"
              m={0}
              rightIcon={<ChevronDownIcon />}
              width="100%"
            >
              <NavigationOption>{selectedExecutor}</NavigationOption>
            </MenuButton>

            <MenuList mt={-2} mx={4} rootProps={{ width: "100%" }} zIndex={500}>
              {[ExecutorType.ShortExecutor, ExecutorType.LongExecutor].map(
                (name) => {
                  return (
                    <MenuItem
                      key={name}
                      bgColor="white"
                      height="50px"
                      isDisabled={name === selectedExecutor}
                      width="100%"
                      onClick={() => handleSelectExecutor(name)}
                    >
                      <NavigationOption width="100%">{name}</NavigationOption>
                    </MenuItem>
                  )
                },
              )}
            </MenuList>
          </Menu>
        </Box>

        <Stack display={onLargeDevice}>
          {[ExecutorType.ShortExecutor, ExecutorType.LongExecutor].map(
            (name) => {
              return (
                <Button
                  key={name}
                  borderColor={
                    name === selectedExecutor ? "gray.600" : undefined
                  }
                  borderWidth={name === selectedExecutor ? "2px" : undefined}
                  variant="secondary"
                  onClick={() => handleSelectExecutor(name)}
                >
                  {name}
                </Button>
              )
            },
          )}
        </Stack>
      </>
    )
  }

  return (
    <Stack direction={{ base: "column", md: "row" }}>
      <Stack
        direction="column"
        pb={{ base: 4, md: 0 }}
        pr={{ base: 0, md: 4 }}
        width={{ base: "100%", md: "30%" }}
      >
        <Text fontWeight="bold" textStyle="sm">
          <CrosshairIcon mr={1} />
          Select Executor
        </Text>
        {displayExecutorsButtons()}
      </Stack>

      <Stack
        alignSelf="flex-start"
        spacing={3}
        width={{ base: "100%", md: "70%" }}
      >
        <GovernanceModuleAave
          chain={chain}
          selectedExecutor={selectedExecutor}
        />
      </Stack>
    </Stack>
  )
}

type GovernanceInformationSectionProps = {
  governanceModuleInformation: any
  displayGovernanceName: boolean
  isLoading: boolean
}
const GovernanceInformationSection: FC<GovernanceInformationSectionProps> = ({
  governanceModuleInformation,
  displayGovernanceName,
  isLoading,
}) => {
  const chainId = governanceModuleInformation?.governor.chainId
  const chains = governanceModuleInformation?.chains

  const chain = chains
    ? chains.find((chain: Chain) => chain && chain.id === chainId)
    : null

  if (isLoading)
    return (
      <>
        <Stack mb={2} px={6} py={2} spacing={4}>
          <Skeleton h="400" w="100%" />
        </Stack>
      </>
    )

  if (!governanceModuleInformation) return null

  const { governor: governance } = governanceModuleInformation

  const getChain = () => {
    if (chain?.useLayer1VotingPeriod && chains) {
      const layer1Chain = chains.find(
        (element: Chain) => element?.id === chain?.layer1Id,
      )

      if (layer1Chain) {
        return { ...chain, blockTime: layer1Chain.blockTime }
      }
    } else {
      return chain
    }
  }

  // Hide this kind of governor for now
  if (governance.kind === GovernorKind.Multiother) {
    return null
  }

  return (
    <GovernanceModuleStandard
      chain={getChain()}
      displayGovernanceName={displayGovernanceName}
      governance={governance}
    />
  )
}

type GovernanceModuleStandardProps = {
  governance: GovernanceModuleInformationQuery["governor"]
  chain?: ArrayElement<GovernanceModuleInformationQuery["chains"]>
  displayGovernanceName: boolean
}
const GovernanceModuleStandard: FC<GovernanceModuleStandardProps> = ({
  chain,
  governance,
  displayGovernanceName,
}) => {
  const {
    contracts,
    parameters,
    quorum,
    token,
    timelockId,
    name,
    metadata,
    type: governorType,
  } = governance
  const { proposalThreshold } = parameters
  const { governor } = contracts

  const { id: tokenId, decimals } = token
  const { address: governorAddress } = governor
  const { address: tokenAddress } = getAssetIdParams(tokenId)
  const timelockAddress = timelockId
    ? getAccountIdParams(timelockId).address
    : "none"

  const { votingPeriodLabel, votingDelayLabel } = getParameterDatesLabel(
    parameters,
    chain,
  )

  const bigProposalThreshold = proposalThreshold
    ? stringToBigNumber(proposalThreshold)
    : BigNumber.from(0)
  const proposalThresholdLabel = getWeightLabel(bigProposalThreshold, decimals)

  const bigQuorum = stringToBigNumber(quorum)
  const quorumLabel = getWeightLabel(bigQuorum, decimals)

  return (
    <>
      {metadata && metadata.description && metadata.description.length > 0 ? (
        <>
          <Stack maxW="100%" pb={2} px={2} spacing={4}>
            <Text textStyle="body.bold.md">
              <AlignLeftIcon mr={1} textStyle="none" /> About
            </Text>
            <Box>
              <Text color="gray.600" fontSize="16px" fontWeight={400}>
                {metadata.description}
              </Text>
            </Box>
          </Stack>

          <Divider />
        </>
      ) : null}
      <Stack maxW="100%" pb={2} px={2} spacing={4}>
        <Text textStyle="body.bold.md">
          <SlidersUpIcon mr={1} textStyle="none" /> Parameters
        </Text>
      </Stack>

      <Stack px={2} spacing={4} w="100%">
        <Box>
          <Flex align="center" justify="space-between" w="full">
            <Text color="gray.600" fontSize="14px" fontWeight={500}>
              Proposal threshold
            </Text>
            <Text color="gray.600" fontSize="14px">
              {proposalThresholdLabel}
            </Text>
          </Flex>
        </Box>

        <Flex align="center" justify="space-between" w="full">
          <Text color="gray.600" fontSize="14px" fontWeight={500}>
            Quorum needed
          </Text>
          <Text color="gray.600" fontSize="14px">
            {quorumLabel}
          </Text>
        </Flex>
        <Flex align="center" justify="space-between" w="full">
          <Text color="gray.600" fontSize="14px" fontWeight={500}>
            Proposal delay
          </Text>
          <Text color="gray.600" fontSize="14px">
            {votingDelayLabel}
          </Text>
        </Flex>
        <Flex align="center" justify="space-between" w="full">
          <Text color="gray.600" fontSize="14px" fontWeight={500}>
            Voting period
          </Text>
          <Text color="gray.600" fontSize="14px">
            {votingPeriodLabel}
          </Text>
        </Flex>

        <Flex align="center" justifyContent="center" w="full">
          <Text mr={1} textStyle="body.regular.md">
            Parameters are governed by the community.{" "}
            <Link
              isExternal
              href={EXTERNAL_ROUTES.tally.docs.governorParameters()}
            >
              <Text as="span" color="gray.600" textStyle="body.bold.md">
                Learn more.
              </Text>
            </Link>
          </Text>
        </Flex>
      </Stack>

      <Divider />

      <Stack direction="row" my={2} px={2} spacing={4}>
        <Text textStyle="body.bold.md">
          <FileInvoiceIcon mr={1} textStyle="none" />
          {displayGovernanceName ? name : "Contracts"}
        </Text>
        <Spacer />
        {governorType === GovernorType.Hub ||
        governorType === GovernorType.Spoke ? (
          <CommonTag>{capitalize(governorType)}</CommonTag>
        ) : null}
        {chain ? <NetworkTag chainId={chain?.id} /> : null}
      </Stack>

      <Stack px={2} spacing={4}>
        <ContractAddress
          address={governorAddress}
          chainId={chain?.id}
          label="Governor"
        />
        <ContractAddress
          address={tokenAddress}
          chainId={chain?.id}
          label="Token"
        />
        <ContractAddress
          address={timelockAddress}
          chainId={chain?.id}
          label="Timelock"
        />
      </Stack>
    </>
  )
}

type GovernanceModuleAaveProps = {
  chain?: ArrayElement<GovernanceModuleInformationQuery["chains"]>
  selectedExecutor: ExecutorType
}

const GovernanceModuleAave: FC<GovernanceModuleAaveProps> = ({
  chain,
  selectedExecutor,
}) => {
  return (
    <>
      {selectedExecutor === ExecutorType.ShortExecutor ? (
        <>
          <Flex align="center" h="2.5rem" px={2}>
            <Text textStyle="body.bold.md">
              <SlidersUpIcon mr={1} textStyle="none" /> Parameters
            </Text>
          </Flex>

          <Stack h="full" position="relative" px={2} spacing={4} w="full">
            <Flex align="center" justify="space-between" w="full">
              <Text color="gray.600" fontSize="14px" fontWeight={500}>
                Proposal threshold
              </Text>
              <Text color="gray.600" fontSize="14px">
                50
              </Text>
            </Flex>
            <Flex align="center" justify="space-between" w="full">
              <Text color="gray.600" fontSize="14px" fontWeight={500}>
                Vote differential
              </Text>
              <Text color="gray.600" fontSize="14px">
                50
              </Text>
            </Flex>
            <Flex align="center" justify="space-between" w="full">
              <Text color="gray.600" fontSize="14px" fontWeight={500}>
                Quorum
              </Text>
              <Text color="gray.600" fontSize="14px">
                {getWeightLabel("320000000000000000000000", 18)}
              </Text>
            </Flex>

            <Flex align="center" justify="space-between" w="full">
              <Text color="gray.600" fontSize="14px" fontWeight={500}>
                Voting duration
              </Text>
              <Text color="gray.600" fontSize="14px">
                {chain ? getDateFromBlocks(19200, chain.blockTime) : 19200}
              </Text>
            </Flex>
            <Flex align="center" justify="space-between" w="full">
              <Text color="gray.600" fontSize="14px" fontWeight={500}>
                Voting delay
              </Text>
              <Text color="gray.600" fontSize="14px">
                {chain ? getDateFromBlocks(7200, chain.blockTime) : 7200}
              </Text>
            </Flex>
          </Stack>
          <Divider />
          <Flex align="center" h="2.5rem" px={2}>
            <Text textStyle="body.bold.md">
              <FileInvoiceIcon mr={1} textStyle="none" />
              Contracts
            </Text>
          </Flex>
          <Stack h="full" px={2} spacing={4} w="full">
            <ContractAddress
              address="0xEE56e2B3D491590B5b31738cC34d5232F378a8D5"
              chainId={chain?.id}
              label="Timelock"
            />
            <ContractAddress
              address="0xEC568fffba86c094cf06b22134B23074DFE2252c"
              chainId={chain?.id}
              label="Governor"
            />
            <ContractAddress
              address="0xb7e383ef9B1E9189Fc0F71fb30af8aa14377429e"
              chainId={chain?.id}
              label="Strategy"
            />
            <ContractAddress
              address="0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9"
              chainId={chain?.id}
              label="Token"
            />
            <ContractAddress
              address="0x4da27a545c0c5B758a6BA100e3a049001de870f5"
              chainId={chain?.id}
              label="Token"
            />
          </Stack>
        </>
      ) : (
        <>
          <Flex align="center" h="2.5rem" px={2}>
            <Text textStyle="body.bold.md">
              <SlidersUpIcon mr={1} textStyle="none" /> Parameters
            </Text>
          </Flex>

          <Stack h="full" position="relative" px={2} spacing={4} w="full">
            <Flex align="center" justify="space-between" w="full">
              <Text color="gray.600" fontSize="14px" fontWeight={500}>
                Proposal threshold
              </Text>
              <Text color="gray.600" fontSize="14px">
                200
              </Text>
            </Flex>
            <Flex align="center" justify="space-between" w="full">
              <Text color="gray.600" fontSize="14px" fontWeight={500}>
                Vote differential
              </Text>
              <Text color="gray.600" fontSize="14px">
                1500
              </Text>
            </Flex>
            <Flex align="center" justify="space-between" w="full">
              <Text color="gray.600" fontSize="14px" fontWeight={500}>
                Quorum
              </Text>
              <Text color="gray.600" fontSize="14px">
                {getWeightLabel("3200000000000000000000000", 18)}
              </Text>
            </Flex>
            <Flex align="center" justify="space-between" w="full">
              <Text color="gray.600" fontSize="14px" fontWeight={500}>
                Voting duration
              </Text>
              <Text color="gray.600" fontSize="14px">
                {chain ? getDateFromBlocks(6400, chain.blockTime) : 6400}
              </Text>
            </Flex>
            <Flex align="center" justify="space-between" w="full">
              <Text color="gray.600" fontSize="14px" fontWeight={500}>
                Voting delay
              </Text>
              <Text color="gray.600" fontSize="14px">
                {chain ? getDateFromBlocks(7200, chain.blockTime) : 7200}
              </Text>
            </Flex>
          </Stack>

          <Divider />

          <Flex align="center" h="2.5rem" px={2}>
            <Text textStyle="body.bold.md">
              <FileInvoiceIcon mr={1} textStyle="none" />
              Contracts
            </Text>
          </Flex>

          <Stack h="full" px={0} spacing={4} w="full">
            <ContractAddress
              address="0x61910EcD7e8e942136CE7Fe7943f956cea1CC2f7"
              chainId={chain?.id}
              label="Timelock"
            />
            <ContractAddress
              address="0xEC568fffba86c094cf06b22134B23074DFE2252c"
              chainId={chain?.id}
              label="Governor"
            />
            <ContractAddress
              address="0xb7e383ef9B1E9189Fc0F71fb30af8aa14377429e"
              chainId={chain?.id}
              label="Strategy"
            />
            <ContractAddress
              address="0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9"
              chainId={chain?.id}
              label="Token"
            />
            <ContractAddress
              address="0x4da27a545c0c5B758a6BA100e3a049001de870f5"
              chainId={chain?.id}
              label="Token"
            />
          </Stack>
        </>
      )}
    </>
  )
}
