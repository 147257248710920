import React from "react"
import { createIcon } from "@chakra-ui/icons"

const TallyBlack = createIcon({
  displayName: "TallyBlack",
  viewBox: "0 0 2170 801",
  path: (
    <g>
      <path
        d="M799.738 254.437H662.952V168.286H1035.14V254.437H897.663V653.535H799.738V254.437Z"
        fill="black"
      />
      <path
        d="M1361.97 320.174V653.836H1274.98V613.487H1273.3C1248.98 644.204 1214.98 659.554 1171.3 659.554C1148.89 659.749 1126.69 655.535 1105.96 647.214C1086.39 639.371 1068.74 627.597 1054.15 612.655C1039.12 597.004 1027.56 578.502 1020.2 558.284C1003.78 512.995 1003.78 463.581 1020.2 418.293C1027.6 397.968 1039 379.272 1053.76 363.249C1068.37 347.811 1086.09 335.506 1105.82 327.114C1125.52 318.722 1146.8 314.402 1168.31 314.455C1187.33 313.818 1206.27 317.394 1223.69 324.919C1241.11 332.443 1256.57 343.721 1268.91 357.902H1270.29V320.245L1361.97 320.174ZM1249.44 554.07C1257.81 545.253 1264.33 534.913 1268.59 523.636C1272.86 512.34 1274.81 500.354 1274.29 488.333C1275.14 463.865 1266.41 439.999 1249.9 421.604C1242.28 412.999 1232.81 406.13 1222.15 401.527C1211.51 396.923 1199.95 394.675 1188.32 394.941C1176.62 394.675 1164.98 396.817 1154.18 401.243C1143.38 405.652 1133.66 412.273 1125.66 420.63C1117.13 429.748 1110.52 440.441 1106.24 452.073C1101.94 463.705 1100.04 476.046 1100.65 488.403C1099.58 512.588 1108.33 536.224 1124.97 554.141C1133.1 562.179 1142.78 568.57 1153.46 572.925C1164.15 577.281 1175.59 579.529 1187.17 579.529C1198.73 579.529 1210.18 577.281 1220.86 572.925C1231.54 568.57 1241.22 562.179 1249.36 554.141L1249.44 554.07Z"
        fill="black"
      />
      <path d="M1441.87 150.67H1533.33V653.836H1441.87V150.67Z" fill="black" />
      <path d="M1619.99 150.67H1711.37V653.836H1619.99V150.67Z" fill="black" />
      <mask
        height="475"
        id="mask0_20_3"
        maskUnits="userSpaceOnUse"
        width="359"
        x="1749"
        y="316"
      >
        <path
          d="M1749.11 316.173H2107.17V790.268H1749.11V316.173Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_20_3)">
        <path
          d="M2106.09 320.175L1922.67 789.011H1828.75L1889.49 633.211L1753.31 320.175H1848.84L1932.45 526.735H1933.44L2011.57 320.175H2106.09Z"
          fill="black"
        />
      </g>
      <path
        clipRule="evenodd"
        d="M441.854 416.343V289.117L65.79 76.7145V37.6758L476.308 269.536V435.8L441.854 416.343ZM344.904 472.413L310.451 452.956V684.727L344.904 704.185V472.413Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M507.752 378.667V251.441L131.688 39.0387V0L542.205 231.86V398.124L507.752 378.667ZM410.27 508.5L376.366 489.5V676L410.27 695V508.5Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        d="M410.518 306.59V472.855L279.114 398.601V741.239L131.475 657.797V315.248L0 240.995V74.7305L410.518 306.59Z"
        fill="black"
      />
    </g>
  ),
})

export default TallyBlack
