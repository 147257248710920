import { EXTERNAL_ROUTES } from "common/constants/routes"

type Help = {
  title: string
  href: string
}

// Hardcoded list of Organization with claim settings
const ORG_CLAIM_HELP_LINKS: Record<string, Record<string, Help[]>> = {
  "https://api.tally.xyz": {
    // OpenDollar: https://www.tally.xyz/gov/opendollar
    "2235789849409881260": [
      {
        title: "Chat with Tally",
        href: EXTERNAL_ROUTES.discord(),
      },
      {
        title: "Open Dollar documentation",
        href: "https://docs.opendollar.com/",
      },
    ],
    // HAI (Sepolia): https://www.tally.xyz/gov/hai-sepolia
    "2246524755874153503": [
      {
        title: "Chat with Tally",
        href: EXTERNAL_ROUTES.discord(),
      },
      {
        title: "HAI documentation",
        href: "https://docs.letsgethai.com/",
      },
    ],
    // HAI: https://www.tally.xyz/gov/hai-old
    "2253911060618675290": [
      {
        title: "Chat with Tally",
        href: EXTERNAL_ROUTES.discord(),
      },
      {
        title: "HAI documentation",
        href: "https://docs.letsgethai.com/",
      },
    ],
    // HAI: https://www.tally.xyz/gov/hai
    "2260960194542438166": [
      {
        title: "Chat with Tally",
        href: EXTERNAL_ROUTES.discord(),
      },
      {
        title: "HAI documentation",
        href: "https://docs.letsgethai.com/",
      },
    ],
  },
  "https://api.staging.tally.xyz": {
    // hai-testnet: https://staging.tally.xyz/gov/hai-testnet
    "2206056275924485329": [
      {
        title: "Chat with Tally",
        href: EXTERNAL_ROUTES.discord(),
      },
      {
        title: "HAI documentation",
        href: "https://docs.reflexer.finance/",
      },
    ],
  },
}

export const getOrganizationClaimHelpLinks = (organizationId: string) => {
  return (
    ORG_CLAIM_HELP_LINKS?.[process.env.NEXT_PUBLIC_TALLY_API_URL]?.[
      organizationId
    ] ?? undefined
  )
}
