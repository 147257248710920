import { SiweMessage } from "siwe"
import type { Signer } from "@ethersproject/abstract-signer"
import invariant from "tiny-invariant"

import { useLogin } from "session/hooks/useLogin"
import { getErrorMessage } from "common/helpers/error"
import { useToast } from "common/hooks/useToast"
import { EXTERNAL_ROUTES } from "common/constants/routes"
import { getMainnetReference } from "web3/helpers/chainReference"
import { GrpcErrorType, fetcher } from "common/helpers/fetcher"
import {
  NonceDocument,
  type NonceQuery,
  type NonceQueryVariables,
} from "query/graphql"

export enum SiweError {
  SignatureRejection = "user rejected signing",
  SafeUnsupportedSiwe = "Method not found",
}

const shouldShowError = (
  omittedErrors: SiweError[],
  errorMessage: SiweError,
) => {
  return !omittedErrors.includes(errorMessage)
}

export function useSiwe() {
  const { login } = useLogin()
  const { toast } = useToast()

  const fetchNonce = async () => {
    const response = await fetcher.gql<NonceQuery, NonceQueryVariables>({
      query: NonceDocument,
      omittedErrors: [GrpcErrorType.NotFound],
      onError: (error) => console.log(error),
    })

    return Promise.resolve(response?.nonce)
  }

  const signInWithEthereum = async ({
    signer,
    address,
    redirectTo,
    omittedErrors = [],
  }: {
    signer: Signer
    address: string
    redirectTo?: string
    omittedErrors?: SiweError[]
  }) => {
    const nonceResponse = await fetchNonce()

    invariant(nonceResponse, '"nonce" must be defined')

    const { nonce, expirationTime, issuedAt, nonceToken } = nonceResponse

    const siweMessage = new SiweMessage({
      uri: process.env.NEXT_PUBLIC_TALLY_SIWE_URI,
      nonce: nonce,
      expirationTime,
      issuedAt,
      domain: window.location.host,
      address,
      version: "1",
      chainId: getMainnetReference(),
      statement:
        "Sign in with Ethereum to Tally and agree to the Terms of Service at terms.tally.xyz",
    })

    try {
      const message = siweMessage.prepareMessage()
      const signature = await signer.signMessage(message)

      login(message, signature, nonceToken, redirectTo)
    } catch (error) {
      const errorMessage = getErrorMessage(error)

      if (
        errorMessage.includes(SiweError.SafeUnsupportedSiwe) &&
        shouldShowError(omittedErrors, errorMessage as SiweError)
      ) {
        toast({
          status: "warning",
          title: "SIWE not supported",
          link: EXTERNAL_ROUTES.tally.docs.signInAsSafe(),
          description:
            "Your wallet does not appear to support Sign in With Ethereum. To update your Safe's profile, sign in as one of the Safe owners. To Vote or Delegate from the Safe, connect as your Safe without signing in.",
        })
      }

      if (
        errorMessage.includes(SiweError.SignatureRejection) &&
        shouldShowError(omittedErrors, errorMessage as SiweError)
      ) {
        toast({
          status: "warning",
          title: "Sign in canceled",
          description:
            "To successfully finish logging in, please accept the suggested action",
        })
      }
    }
  }

  return {
    signInWithEthereum,
  }
}
