import type { ReactElement } from "react"
import React, { useState, useRef } from "react"
import { isAddress } from "@ethersproject/address"
import type { ButtonProps, ComponentWithAs, IconProps } from "@chakra-ui/react"
import {
  Flex,
  MenuGroup,
  HStack,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  Button,
  Stack,
  Text,
  Icon,
  Tooltip,
  Box,
} from "@chakra-ui/react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useAccount } from "wagmi"

import { Spinner } from "ui/components/icons/font-awesome/Spinner"
import Safe from "ui/components/icons/Safe"
import Check from "ui/components/icons/Check"
import { User } from "ui/components/icons/font-awesome/User"
import { Gear } from "ui/components/icons/font-awesome/Gear"
import { RightFromBracket } from "ui/components/icons/font-awesome/RightFromBracket"
import { ChevronDown } from "ui/components/icons/font-awesome/ChevronDown"
import UserAvatar from "common/components/UserAvatar"
import { EXTERNAL_ROUTES, ROUTES } from "common/constants/routes"
import { shortString, subString } from "common/helpers/string"
import Link from "common/components/Link"
import { House } from "ui/components/icons/font-awesome/House"
import type { Me } from "user/providers/MeProvider"
import { useMe } from "user/providers/MeProvider"
import { useLogout } from "session/hooks/useLogout"
import type { AccountId } from "query/graphql"
import { AccountType, useAddressSafesQuery } from "query/graphql"
import { useLoginAsSafe } from "session/hooks/useLoginAsSafe"
import { getAccountAddress } from "web3/helpers/accountId"
import { useSiwe } from "web3/hooks/useSiwe"
import { Wallet } from "ui/components/icons/font-awesome/Wallet"
import { useConnectorIcon } from "web3/hooks/useConnectorIcon"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { useSignerStore } from "web3/providers/SignerProvider"
import { shortenIfAddress } from "web3/helpers/address"
import { Bell } from "ui/components/icons/font-awesome/Bell"
import { getDisplayName } from "user/helpers/user"
import GenericNetworkIcon from "web3/components/icons/GenericChainIcon"
import { findChainBySafeId } from "web3/helpers/chains"

function HeaderUserMenu({
  redirectTo,
  slug,
  isWhiteLabel,
  defaultDisplayName = undefined,
  isButtonDisabled = false,
  ...buttonProps
}: {
  redirectTo?: string
  slug: string
  isWhiteLabel: boolean
  defaultDisplayName?: string
  isButtonDisabled?: boolean
} & ButtonProps) {
  const { logout } = useLogout()
  const { signer } = useSignerStore()
  const me = useMe()
  const { address: walletAddress } = useAccount()
  const { signInWithEthereum } = useSiwe()
  const connectorIcon = useConnectorIcon()

  const handleLogoutClick = () => {
    logout(redirectTo)
  }

  if (!me) {
    return (
      <Button
        isDisabled
        _focusVisible={{
          boxShadow: "none",
          borderColor: "transparent",
          outline: "3px solid hsla(216, 12%, 84%, 1)",
          outlineOffset: "2px",
        }}
        borderColor="gray.300"
        data-qa="connect-wallet-action"
        variant="secondary"
        {...buttonProps}
      >
        Loading...
      </Button>
    )
  }

  const { address, picture, type, id } = me

  const displayName = defaultDisplayName ?? getDisplayName(me)

  return (
    <Menu placement="bottom-end">
      {isButtonDisabled ? (
        <MenuButton>
          <Button
            _focusVisible={{
              boxShadow: "none",
              outline: "3px solid hsla(216, 12%, 84%, 1)",
              outlineOffset: "2px",
            }}
            borderColor="gray.300"
            borderWidth="1px"
            data-qa="menu-action-btn"
            variant="secondary"
            {...buttonProps}
          >
            <Stack isInline align="center">
              <UserAvatar address={address} size={6} src={picture} />
              {displayName ? (
                <Text
                  data-qa="menu-action-btn-text"
                  fontWeight="bold"
                  textStyle="sm"
                >
                  {isAddress(displayName)
                    ? shortString(displayName)
                    : displayName}
                </Text>
              ) : null}
            </Stack>
          </Button>
        </MenuButton>
      ) : (
        <MenuButton
          _hover={{
            borderColor: "gray.400",
            borderWidth: "1px",
          }}
          border="gray.200"
          borderRadius="lg"
          height="44px"
        >
          <Button
            _focusVisible={{
              boxShadow: "none",
              outline: "3px solid hsla(216, 12%, 84%, 1)",
              outlineOffset: "2px",
            }}
            border="none"
            data-qa="menu-action-btn"
            variant="secondary"
            {...buttonProps}
          >
            <Stack isInline align="center">
              <UserAvatar address={address} size={6} src={picture} />
              {displayName ? (
                <Text
                  data-qa="menu-action-btn-text"
                  fontWeight="bold"
                  textStyle="sm"
                >
                  {isAddress(displayName)
                    ? shortString(displayName)
                    : subString(displayName, 24)}
                </Text>
              ) : null}
              <Icon as={ChevronDown} h={4} w={4} />
            </Stack>
          </Button>
        </MenuButton>
      )}
      <MenuList px={1} py={1}>
        {isWhiteLabel ? (
          <MenuLink
            icon={User}
            label="Delegate profile"
            route={getWhiteLabelRoute(
              ROUTES.governance.delegate.profile(slug, address),
            )}
          />
        ) : (
          <>
            <MenuLink
              dataQa="dropdown-main-menu-link-yourDAOs"
              icon={House}
              label="Your DAOs"
              route={ROUTES.user.yourDAOs()}
            />
            {address ? (
              <MenuLink
                dataQa="dropdown-main-menu-link-profile"
                icon={User}
                label="Profile"
                route={ROUTES.profile(address)}
              />
            ) : null}
          </>
        )}
        {!isWhiteLabel ? (
          <MenuLink
            isExternal
            dataQa="dropdown-main-menu-link-notifications"
            icon={Bell}
            label="Notifications"
            route={EXTERNAL_ROUTES.notifications.index(id)}
          />
        ) : null}
        <MenuLink
          dataQa="dropdown-main-menu-link-settings"
          icon={Gear}
          label="Settings"
          route={ROUTES.user.settings()}
        />
        {type === AccountType.Safe && walletAddress && signer ? (
          <MenuItem
            h={10}
            onClick={() =>
              signInWithEthereum({ address: walletAddress, signer })
            }
          >
            <Stack isInline align="center" px={1} spacing={2} w="full">
              <Icon as={Wallet} color="gray.600" h={4} w={4} />
              <Stack align="center" direction="row" spacing={2}>
                <Text
                  color="gray.900"
                  data-qa="dropdown-main-menu-safe-switchto-text"
                  fontSize="sm"
                  fontWeight="medium"
                >
                  Switch to
                </Text>
                <Stack align="center" direction="row" rounded="md">
                  <Icon as={connectorIcon} color="gray.600" h={5} w={5} />
                  <Text color="gray.900" fontSize="sm" fontWeight="medium">
                    {shortString(walletAddress)}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </MenuItem>
        ) : (
          <SafesMenu me={me} />
        )}
        <MenuLink
          dataQa="dropdown-main-menu-btn-logout"
          icon={RightFromBracket}
          id="logout-action"
          label="Log out"
          onClick={handleLogoutClick}
        />
      </MenuList>
    </Menu>
  )
}

const SafesMenu = ({ me }: { me: Me }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { address, picture, id } = me
  const menuListRef = useRef<HTMLDivElement>(null)

  const displayName = getDisplayName(me)

  const { data, isLoading, isFetched } = useAddressSafesQuery(
    { accountId: id },
    { enabled: isOpen },
  )
  const { loginAsSafe } = useLoginAsSafe()

  const handleLoginAsSafe = (safe: AccountId) => {
    loginAsSafe(safe)
  }

  const safeIds = data?.account.safes ?? []

  function isMenuOpen() {
    if (typeof window === "undefined") return

    const style = menuListRef.current
      ? window.getComputedStyle(menuListRef.current)
      : undefined
    setIsOpen(style?.visibility === "hidden")
  }

  return (
    <Menu placement="left-start">
      <MenuItem
        _hover={{ bgColor: "gray.100", color: "black" }}
        as={MenuButton}
        bgColor="auto"
        borderRadius="lg"
        className="no-underline"
        color="gray.900"
        data-qa="dropdown-main-menu-btn-signinassafe"
        fontSize="sm"
        fontWeight="medium"
        mx={0}
        px={0}
        py={0}
        width="100%"
        onClick={isMenuOpen}
      >
        <HStack
          _hover={{ bgColor: "gray.100", color: "black" }}
          align="center"
          bgColor="auto"
          color="gray.900"
          fontSize="sm"
          fontWeight="medium"
          px={4}
          py={2}
          width="100%"
        >
          <Box _hover={{ color: "gray.900" }} color="gray.500">
            <Icon as={Safe} h={4} w={4} />
          </Box>
          <Text pt={1}>Sign in as Safe</Text>
          <Box _hover={{ color: "gray.900" }} color="gray.500">
            <Icon as={ChevronRightIcon} h={4} w={4} />
          </Box>
        </HStack>
      </MenuItem>
      <MenuList ref={menuListRef} p={1}>
        <MenuGroup fontWeight="medium" mx={3} title="EOA">
          <MenuItem h={10}>
            <Stack isInline align="center" justify="space-between" w="full">
              <Stack isInline align="center">
                <UserAvatar address={address} size={6} src={picture} />
                {displayName ? (
                  <Text fontWeight="bold" textStyle="sm">
                    {isAddress(displayName)
                      ? shortString(displayName)
                      : displayName}
                  </Text>
                ) : null}
              </Stack>
              <Icon as={Check} h={4} w={4} />
            </Stack>
          </MenuItem>
        </MenuGroup>
        <MenuGroup fontWeight="medium" mx={3} title="Safes">
          {isLoading ? (
            <MenuItem h={10}>
              <Stack
                isInline
                align="center"
                justify="center"
                spacing={3}
                w="full"
              >
                <Icon as={Spinner} className="rotate" fill="gray.400" />
                <Text color="gray.400" textStyle="body.bold.sm">
                  Loading safes
                </Text>
              </Stack>
            </MenuItem>
          ) : null}
          {isFetched && safeIds.length > 0 ? (
            <Flex direction="column" maxH="400px" overflowY="auto">
              {safeIds?.map((safeId, index) => {
                const safeAddress = getAccountAddress(safeId)

                const chain = findChainBySafeId(safeId)
                const networkIcon = chain
                  ? GenericNetworkIcon(chain)
                  : undefined

                const tooltipLabel = `${safeAddress} is on ${
                  !!chain ? chain.mediumName : "an unsupported network"
                }`

                return (
                  <MenuItem
                    key={`safe-${index}`}
                    h={10}
                    onClick={() => handleLoginAsSafe(safeId)}
                  >
                    <Tooltip hasArrow label={tooltipLabel} placement="right">
                      <Stack
                        isInline
                        align="center"
                        justify="left"
                        spacing={3}
                        w="full"
                      >
                        {!!networkIcon ? (
                          <Icon as={networkIcon} h={5} w={5} />
                        ) : (
                          <Icon as={Safe} h={5} w={5} />
                        )}
                        <Text color="gray.600" textStyle="body.bold.md">
                          {shortenIfAddress(safeAddress)}
                        </Text>
                      </Stack>
                    </Tooltip>
                  </MenuItem>
                )
              })}
            </Flex>
          ) : null}
          {isFetched && safeIds.length === 0 ? (
            <Flex align="center" justify="center" mx={3} my={1.5} w="full">
              <Text color="gray.600" textStyle="body.bold.md">
                You don&apos;t own a safe
              </Text>
            </Flex>
          ) : null}
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

export default HeaderUserMenu

function MenuLink({
  icon,
  label,
  isExternal,
  onClick,
  route,
  rightIcon,
  dataQa,
}: {
  route?: string
  icon: ComponentWithAs<"svg", IconProps>
  label: string | ReactElement
  id?: string
  onClick?: () => void
  isExternal?: boolean
  rightIcon?: ComponentWithAs<"svg", IconProps>
  dataQa?: string
}) {
  return (
    <>
      {route != null ? (
        <MenuItem
          key={route}
          as={Link}
          className="no-underline"
          data-qa={dataQa}
          href={route}
          isExternal={isExternal}
          mx={0}
          px={0}
          py={0}
        >
          <HStack
            _hover={{ bgColor: "gray.100", color: "black" }}
            align="center"
            bgColor="auto"
            borderRadius="lg"
            color="gray.900"
            fontSize="sm"
            fontWeight="medium"
            px={4}
            py={2}
            width="100%"
          >
            {icon ? (
              <Box _hover={{ color: "gray.900" }} color="gray.500">
                <Icon as={icon} h={4} w={4} />
              </Box>
            ) : null}
            <Text pt={1}>{label}</Text>
            {rightIcon ? (
              <Box _hover={{ color: "gray.900" }} color="gray.500">
                <Icon as={rightIcon} h={4} w={4} />
              </Box>
            ) : null}
          </HStack>
        </MenuItem>
      ) : (
        <MenuItem
          borderRadius="lg"
          data-qa={dataQa}
          mx={0}
          px={0}
          py={0}
          onClick={onClick}
        >
          <HStack
            _hover={{ bgColor: "gray.100", color: "black" }}
            align="center"
            bgColor="auto"
            color="gray.900"
            fontSize="sm"
            fontWeight="medium"
            px={4}
            py={2}
            width="100%"
          >
            {icon ? (
              <Box _hover={{ color: "gray.900" }} color="gray.500">
                <Icon as={icon} h={4} w={4} />
              </Box>
            ) : null}
            <Text pt={1}>{label}</Text>
            {rightIcon ? (
              <Box _hover={{ color: "gray.900" }} color="gray.500">
                <Icon as={rightIcon} h={4} w={4} />
              </Box>
            ) : null}
          </HStack>
        </MenuItem>
      )}
    </>
  )
}
