export const BANNED_ADDRESSES = [
    "0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C",
    "0xD0975B32cEa532eaDDdFC9c60481976e39dB3472",
    "0x1967D8Af5Bd86A497fb3DD7899A020e47560dAAF",
    "0x38735f03b30FbC022DdD06ABED01F0Ca823C6a94",
    "0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b",
    "0x97B1043ABD9E6FC31681635166d430a458D14F9C",
    "0xb6f5ec1A0a9cd1526536D3F0426c429529471F40",
    "0x901bb9583b24D97e995513C6778dc6888AB6870e",
    "0xA7e5d5A720f06526557c513402f2e6B5fA20b008",
    "0xfEC8A60023265364D066a1212fDE3930F6Ae8da7",
    "0x7Db418b5D567A4e0E8c59Ad71BE1FcE48f3E6107",
    "0x72a5843cc08275C8171E582972Aa4fDa8C397B2A",
    "0x7F19720A857F834887FC9A7bC0a0fBe7Fc7f8102",
    "0x4F47Bc496083C727c5fbe3CE9CDf2B0f6496270c",
    "0x9F4cda013E354b8fC285BF4b9A60460cEe7f7Ea9",
    "0x3CBdeD43EFdAf0FC77b9C55F6fC9988fCC9b757d",
    "0x58E8dCC13BE9780fC42E8723D8EaD4CF46943dF2",
    "0x01e2919679362dFBC9ee1644Ba9C6da6D6245BB1",
    "0x2FC93484614a34f26F7970CBB94615bA109BB4bf",
    "0x26903a5a198D571422b2b4EA08b56a37cbD68c89",
    "0xB20c66C4DE72433F3cE747b58B86830c459CA911",
    "0x2573BAc39EBe2901B4389CD468F2872cF7767FAF",
    "0x527653eA119F3E6a1F5BD18fbF4714081D7B31ce",
    "0x653477c392c16b0765603074f157314Cc4f40c32",
    "0x88fd245fEdeC4A936e700f9173454D1931B4C307",
    "0x09193888b3f38C82dEdfda55259A82C0E7De875E",
    "0x5cab7692D4E94096462119ab7bF57319726Eed2A",
    "0x756C4628E57F7e7f8a459EC2752968360Cf4D1AA",
    "0x722122dF12D4e14e13Ac3b6895a86e84145b6967",
    "0x94A1B5CdB22c43faab4AbEb5c74999895464Ddaf",
    "0xb541fc07bC7619fD4062A54d96268525cBC6FfEF",
    "0xD82ed8786D7c69DC7e052F7A542AB047971E73d2",
    "0xF67721A2D8F736E75a49FdD7FAd2e31D8676542a",
    "0x9AD122c22B14202B4490eDAf288FDb3C7cb3ff5E",
    "0xD691F27f38B395864Ea86CfC7253969B409c362d",
    "0xaEaaC358560e11f52454D997AAFF2c5731B6f8a6",
    "0x1356c899D8C9467C7f71C195612F8A395aBf2f0a",
    "0xA60C772958a3eD56c1F15dD055bA37AC8e523a0D",
    "0xBA214C1c1928a32Bffe790263E38B4Af9bFCD659",
    "0xb1C8094B234DcE6e03f10a5b673c1d8C69739A00",
    "0x12D66f87A04A9E220743712cE6d9bB1B5616B8Fc",
    "0x47CE0C6eD5B0Ce3d3A51fdb1C52DC66a7c3c2936",
    "0x910Cbd523D972eb0a6f4cAe4618aD62622b39DbF",
    "0xA160cdAB225685dA1d56aa342Ad8841c3b53f291",
    "0xD4B88Df4D29F5CedD6857912842cff3b20C8Cfa3",
    "0xFD8610d20aA15b7B2E3Be39B396a1bC3516c7144",
    "0x07687e702b410Fa43f4cB4Af7FA097918ffD2730",
    "0x23773E65ed146A459791799d01336DB287f25334",
    "0x22aaA7720ddd5388A3c0A3333430953C68f1849b",
    "0x03893a7c7463AE47D46bc7f091665f1893656003",
    "0x2717c5e28cf931547B621a5dddb772Ab6A35B701",
    "0xD21be7248e0197Ee08E0c20D4a96DEBdaC3D20Af",
    "0x4736dCf1b7A3d580672CcE6E7c65cd5cc9cFBa9D",
    "0xDD4c48C0B24039969fC16D1cdF626eaB821d3384",
    "0xd96f2B1c14Db8458374d9Aca76E26c3D18364307",
    "0x169AD27A470D064DEDE56a2D3ff727986b15D52B",
    "0x0836222F2B2B24A3F36f98668Ed8F0B38D1a872f",
    "0x178169B423a011fff22B9e3F3abeA13414dDD0F1",
    "0x610B717796ad172B316836AC95a2ffad065CeaB4",
    "0xbB93e510BbCD0B7beb5A853875f9eC60275CF498",
    "0x84443CFd09A48AF6eF360C6976C5392aC5023a1F",
    "0xd47438C816c9E7f2E2888E060936a499Af9582b3",
    "0x330bdFADE01eE9bF63C209Ee33102DD334618e0a",
    "0x1E34A77868E19A6647b1f2F47B51ed72dEDE95DD",
    "0xdf231d99Ff8b6c6CBF4E9B9a945CBAcEF9339178",
    "0xaf4c0B70B2Ea9FB7487C7CbB37aDa259579fe040",
    "0xa5C2254e4253490C54cef0a4347fddb8f75A4998",
    "0xaf8d1839c3c67cf571aa74B5c12398d4901147B3",
    "0x6Bf694a291DF3FeC1f7e69701E3ab6c592435Ae7",
    "0x3aac1cC67c2ec5Db4eA850957b967Ba153aD6279",
    "0x723B78e67497E85279CB204544566F4dC5d2acA0",
    "0x0E3A09dDA6B20aFbB34aC7cD4A6881493f3E7bf7",
    "0x76D85B4C0Fc497EeCc38902397aC608000A06607",
    "0xCC84179FFD19A1627E79F8648d09e095252Bc418",
    "0xD5d6f8D9e784d0e26222ad3834500801a68D027D",
    "0x407CcEeaA7c95d2FE2250Bf9F2c105aA7AAFB512",
    "0x833481186f16Cece3f1Eeea1a694c42034c3a0dB",
    "0xd8D7DE3349ccaA0Fde6298fe6D7b7d0d34586193",
    "0x8281Aa6795aDE17C8973e1aedcA380258Bc124F9",
    "0x57b2B8c82F065de8Ef5573f9730fC1449B403C9f",
    "0xF60dD140cFf0706bAE9Cd734Ac3ae76AD9eBC32A",
    "0x8589427373D6D84E98730D7795D8f6f8731FDA16",
    "0x39D908dac893CBCB53Cc86e0ECc369aA4DeF1A29",
    "0xc2a3829F459B3Edd87791c74cD45402BA0a20Be3",
    "0x3AD9dB589d201A710Ed237c829c7860Ba86510Fc",
    "0x83E5bC4Ffa856BB84Bb88581f5Dd62A433A25e0D",
    "0x08b2eFdcdB8822EfE5ad0Eae55517cf5DC544251",
    "0x04DBA1194ee10112fE6C3207C0687DEf0e78baCf",
    "0x0Ee5067b06776A89CcC7dC8Ee369984AD7Db5e06",
    "0x502371699497d08D5339c870851898D6D72521Dd",
    "0x5A14E72060c11313E38738009254a90968F58f51",
    "0xEFE301d259F525cA1ba74A7977b80D5b060B3ccA",
    "0x7F367cC41522cE07553e823bf3be79A889DEbe1B",
    "0xe7aa314c77F4233C18C6CC84384A9247c0cf367B",
    "0x308eD4B7b49797e1A98D3818bFF6fe5385410370",
    "0x2f389cE8bD8ff92De3402FFCe4691d17fC4f6535",
    "0x19Aa5Fe80D33a56D56c78e82eA5E50E5d80b4Dff",
    "0x05E0b5B40B7b66098C2161A5EE11C5740A3A7C45",
    "0x23173fE8b96A4Ad8d2E17fB83EA5dcccdCa1Ae52",
    "0x538Ab61E8A9fc1b2f93b3dd9011d662d89bE6FE6",
    "0x94Be88213a387E992Dd87DE56950a9aef34b9448",
    "0x242654336ca2205714071898f67E254EB49ACdCe",
    "0x776198CCF446DFa168347089d7338879273172cF",
    "0xeDC5d01286f99A066559F60a585406f3878a033e",
    "0xD692Fd2D0b2Fbd2e52CFa5B5b9424bC981C30696",
    "0xCa0840578f57fE71599D29375e16783424023357",
    "0xDF3A408c53E5078af6e8fb2A85088D46Ee09A61b",
    "0x743494b60097A2230018079c02fe21a7B687EAA5",
    "0x94C92F096437ab9958fC0A37F09348f30389Ae79",
    "0x5efda50f22d34F262c29268506C5Fa42cB56A1Ce",
    "0x2F50508a8a3D323B91336FA3eA6ae50E55f32185",
    "0xCEe71753C9820f063b38FDbE4cFDAf1d3D928A80",
    "0xffbaC21a641Dcfe4552920138D90F3638B3c9fba",
    "0x179f48C78f57A3A78f0608cC9197B8972921d1D2",
    "0xb04E030140b30C27bcdfaafFFA98C57d80eDa7B4",
    "0x77777FeDdddFfC19Ff86DB637967013e6C6A116C",
    "0x3eFA30704D2b8BBAc821307230376556cF8CC39e",
    "0x746Aebc06D2aE31B71ac51429A19D54E797878E9",
    "0xd90e2f925DA726b50C4Ed8D0Fb90Ad053324F31b",
    "0x5f6c97C6AD7bdd0AE7E0Dd4ca33A4ED3fDabD4D7",
    "0xf4B067dD14e95Bab89Be928c07Cb22E3c94E0DAA",
    "0x098B716B8Aaf21512996dC57EB0615e2383E2f96",
    "0xa0e1c89Ef1a489c9C7dE96311eD5Ce5D32c20E4B",
    "0x3Cffd56B47B7b41c56258D9C7731ABaDc360E073",
    "0x53b6936513e738f44FB50d2b9476730C0Ab3Bfc1",
    "0x35fB6f6DB4fb05e6A4cE86f2C93691425626d4b1",
    "0xF7B31119c2682c88d88D455dBb9d5932c65Cf1bE",
    "0x3e37627dEAA754090fBFbb8bd226c1CE66D255e9",
    "0x08723392Ed15743cc38513C4925f5e6be5c17243",
    "0x67d40EE1A85bf4a4Bb7Ffae16De985e8427B6b45",
    "0x6F1cA141A28907F78Ebaa64fb83A9088b02A8352",
    "0x6aCDFBA02D390b97Ac2b2d42A63E85293BCc160e",
    "0x48549A34AE37b12F6a30566245176994e17C6b4A",
    "0x5512d943eD1f7c8a43F3435C85F7aB68b30121b0",
    "0xC455f7fd3e0e12afd51fba5c106909934D8A0e4a",
    "0x7FF9cFad3877F21d41Da833E2F775dB0569eE3D9",
    "0x1da5821544e25c636c1417Ba96Ade4Cf6D2f9B5A"
]