import type { FlexProps, LinkProps } from "@chakra-ui/react"
import {
  Spinner,
  Container,
  IconButton,
  HStack,
  Stack,
  Text,
  useDisclosure,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Flex,
  Button,
} from "@chakra-ui/react"
import type { FC } from "react"
import React, { useState, useRef, useEffect } from "react"
import { useAccount } from "wagmi"

import HeaderUserMenuLegacy from "ui/components/header/HeaderUserMenuLegacy"
import { shortenIfAddress } from "web3/helpers/address"
import SearchDaosModal from "common/components/SearchDaosModal"
import Link from "common/components/Link"
import HeaderTopBorder from "ui/components/header/HeaderTopBorder"
import { EXTERNAL_ROUTES, ROUTES } from "common/constants/routes"
import ConnectAccountButton from "web3/components/ConnectAccountButton"
import WalletSelectionModal from "web3/components/WalletSelectionModal"
import { MagnifyingGlass } from "ui/components/icons/font-awesome/MagnifyingGlass"
import { useDevice } from "common/hooks/useDevice"
import { Bars } from "ui/components/icons/font-awesome/Bars"
import SearchDaosDrawer from "common/components/SearchDaosDrawer"
import { X } from "ui/components/icons/font-awesome/X"
import ClientOnly from "common/components/ClientOnly"
import { User } from "ui/components/icons/font-awesome/User"
import { Gear } from "ui/components/icons/font-awesome/Gear"
import { RightFromBracket } from "ui/components/icons/font-awesome/RightFromBracket"
import { House } from "ui/components/icons/font-awesome/House"
import HeaderAddressButton from "ui/components/header/HeaderAddressButton"
import { useSession } from "session/providers/SessionProvider"
import { useLogout } from "session/hooks/useLogout"
import { AwaitRender } from "common/components/AwaitRender"
import TallyBlack from "ui/components/icons/tally/TallyBlack"
import Safe from "ui/components/icons/Safe"
import type { AccountId } from "query/graphql"
import { useAddressSafesQuery, useMainLayoutHeaderQuery } from "query/graphql"
import { useMe } from "user/providers/MeProvider"
import { getAccountAddress } from "web3/helpers/accountId"
import { useRouter } from "common/hooks/useRouter"
import { useLoginAsSafe } from "session/hooks/useLoginAsSafe"
import { useSiwe } from "web3/hooks/useSiwe"
import { Wallet } from "ui/components/icons/font-awesome/Wallet"
import { useConnectorIcon } from "web3/hooks/useConnectorIcon"
import { shortString } from "common/helpers/string"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { WHITELABEL_GOVERNORS_IDS } from "whitelabel/constants/governorId"
import GovernanceAvatar from "governance/components/GovernanceAvatar"
import { useSignerStore } from "web3/providers/SignerProvider"
import { Bell } from "ui/components/icons/font-awesome/Bell"
import GenericNetworkIcon from "web3/components/icons/GenericChainIcon"
import { findChainBySafeId } from "web3/helpers/chains"
import HeaderAccountMenu from "ui/components/header/HeaderAccountMenu"

const MainLayoutHeaderLegacy: FC<
  {
    redirectTo?: {
      login?: string
      logout?: string
      disconnect?: string
    }
    isWhiteLabel?: boolean
    displayConnectButtons?: boolean
  } & FlexProps
> = ({
  isWhiteLabel = false,
  redirectTo = {},
  displayConnectButtons = true,
  ...flexProps
}) => {
  const [governanceSlug, setGovernanceSlug] = useState("")
  const { address } = useAccount()
  const { isLoggedIn } = useSession()
  const searchDaosToastDisclousure = useDisclosure()
  const searchDaosDrawerDisclousure = useDisclosure()
  const hamburgerDisclousure = useDisclosure()
  const walletSelectionDisclousure = useDisclosure()
  const searchModalRef = useRef(null)
  const searchDrawerRef = useRef(null)
  const hamburgerRef = useRef(null)
  const { onLargeDevice, onLittleDevice } = useDevice()

  const { data } = useMainLayoutHeaderQuery(
    { input: { slug: governanceSlug } },
    {
      enabled: isWhiteLabel && Boolean(governanceSlug),
    },
  )

  useEffect(() => {
    if (typeof window.location.host === "undefined" || !isWhiteLabel) return

    const origin = window.location.host
    // @ts-expect-error nothing to do
    const governanceSlug = WHITELABEL_GOVERNORS_IDS[origin] as string

    setGovernanceSlug(governanceSlug)
  }, [isWhiteLabel])

  // TODO(@nicolas): Internal flag to quickly rollback to the previous version is some issue is found
  const displayUnifiedButtons = true

  return (
    <AwaitRender
      loader={
        <Flex
          align="center"
          as="nav"
          bg="white"
          borderBottom="1px solid hsla(220, 17%, 93%, 1)"
          h={20}
          justify="space-between"
          w="full"
          {...flexProps}
        >
          <Container
            display="flex"
            justifyContent="space-between"
            maxW="6xl"
            px={{ base: 4, lg: 0 }}
          >
            <Stack align="center" direction="row" spacing={4}>
              {isWhiteLabel ? (
                <Link
                  className="no-underline"
                  href={ROUTES.governance.id(governanceSlug)}
                >
                  {data?.organization?.metadata?.icon ? (
                    <GovernanceAvatar
                      size={12}
                      src={data?.organization?.metadata?.icon ?? ""}
                    />
                  ) : null}
                </Link>
              ) : (
                <Link className="no-underline" h="36px" href={ROUTES.home()}>
                  <Icon as={TallyBlack} h="full" w={{ base: 24, lg: 24 }} />
                </Link>
              )}
            </Stack>
          </Container>
        </Flex>
      }
      ms={200}
    >
      <HeaderTopBorder />
      <Flex
        align="center"
        as="nav"
        bg="white"
        borderBottom="1px solid hsla(220, 17%, 93%, 1)"
        h={20}
        justify="space-between"
        w="full"
        {...flexProps}
      >
        <Container
          display="flex"
          justifyContent="space-between"
          maxW="6xl"
          px={{ base: 4, lg: 0 }}
        >
          <Stack align="center" direction="row" spacing={4}>
            {isWhiteLabel ? (
              <Link
                className="no-underline"
                href={
                  isWhiteLabel
                    ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
                    : ROUTES.governance.id(governanceSlug)
                }
              >
                {data?.organization?.metadata?.icon ? (
                  <GovernanceAvatar
                    size={12}
                    src={data?.organization?.metadata?.icon ?? ""}
                  />
                ) : null}
              </Link>
            ) : (
              <>
                <Link className="no-underline" h="36px" href={ROUTES.home()}>
                  <Icon as={TallyBlack} h="full" w={{ base: 24, lg: 24 }} />
                </Link>
                <Button
                  ref={searchModalRef}
                  _focusVisible={{
                    boxShadow: "none",
                    borderColor: "transparent",
                    outline: "3px solid hsla(216, 12%, 84%, 1)",
                    outlineOffset: "2px",
                  }}
                  aria-label="Search for a DAO"
                  borderColor="transparent"
                  data-qa="mainlayout-searchDAOs"
                  display={onLargeDevice}
                  variant="secondary"
                  onClick={() => searchDaosToastDisclousure.onOpen()}
                >
                  <Icon
                    as={MagnifyingGlass}
                    fill="gray.600"
                    h={6}
                    mr={2}
                    w={4}
                  />
                  <Text color="gray.600" fontWeight="medium" textStyle="md">
                    Search DAOs
                  </Text>
                </Button>
              </>
            )}
          </Stack>
          <Stack
            align="center"
            direction="row"
            display={onLargeDevice}
            spacing={4}
          >
            <Links
              governanceSlug={governanceSlug}
              isWhiteLabel={isWhiteLabel}
            />

            {displayConnectButtons && displayUnifiedButtons ? (
              <Stack align="center" direction="row" spacing={2}>
                <ClientOnly>
                  <HeaderAccountMenu
                    isWhiteLabel={isWhiteLabel}
                    redirectTo={redirectTo}
                  />
                </ClientOnly>
              </Stack>
            ) : null}

            {displayConnectButtons && !displayUnifiedButtons ? (
              <Stack align="center" direction="row" spacing={2}>
                <ClientOnly>
                  {address ? (
                    <HeaderAddressButton
                      redirectTo={redirectTo?.disconnect}
                      signerAddress={address}
                      onOpen={() => walletSelectionDisclousure.onOpen()}
                    />
                  ) : null}
                </ClientOnly>
                {isLoggedIn ? (
                  <HStack>
                    <ClientOnly>
                      <HeaderUserMenuLegacy
                        isWhiteLabel={isWhiteLabel}
                        redirectTo={redirectTo?.logout}
                        slug={governanceSlug}
                      />
                    </ClientOnly>
                  </HStack>
                ) : (
                  <ClientOnly>
                    <ConnectAccountButton
                      redirectTo={redirectTo?.login}
                      variant="primary"
                      onOpen={() => walletSelectionDisclousure.onOpen()}
                    />
                  </ClientOnly>
                )}
              </Stack>
            ) : null}
          </Stack>
          <HStack align="center" display={onLittleDevice} spacing={2}>
            <IconButton
              ref={searchDrawerRef}
              _focusVisible={{
                boxShadow: "none",
                borderColor: "transparent",
                outline: "3px solid hsla(216, 12%, 84%, 1)",
                outlineOffset: "2px",
              }}
              aria-label="Go to home page"
              bg="white"
              icon={
                <Icon as={MagnifyingGlass} fill="neutral.500" h={4} w={4} />
              }
              p={1}
              onClick={() => searchDaosDrawerDisclousure.onOpen()}
            />
            <IconButton
              ref={hamburgerRef}
              _focusVisible={{
                boxShadow: "none",
                borderColor: "transparent",
                outline: "3px solid hsla(216, 12%, 84%, 1)",
                outlineOffset: "2px",
              }}
              aria-label="Go to home page"
              bg="white"
              icon={<Icon as={Bars} fill="neutral.500" h={4} w={4} />}
              p={1}
              onClick={() => hamburgerDisclousure.onOpen()}
            />
          </HStack>
        </Container>
      </Flex>
      <WalletSelectionModal
        isOpen={walletSelectionDisclousure.isOpen}
        onClose={() => walletSelectionDisclousure.onClose()}
      />
      <SearchDaosModal
        finalRef={searchModalRef}
        isOpen={searchDaosToastDisclousure.isOpen}
        onClose={() => searchDaosToastDisclousure.onClose()}
      />
      <SearchDaosDrawer
        finalRef={searchDrawerRef}
        isOpen={searchDaosDrawerDisclousure.isOpen}
        onClose={() => searchDaosDrawerDisclousure.onClose()}
      />
      <HamburgerDrawer
        displayConnectButtons={displayConnectButtons}
        finalRef={hamburgerRef}
        governanceSlug={governanceSlug}
        isOpen={hamburgerDisclousure.isOpen}
        isWhiteLabel={isWhiteLabel}
        redirectTo={redirectTo}
        onClose={() => hamburgerDisclousure.onClose()}
        onOpen={() => {
          hamburgerDisclousure.onClose()
          walletSelectionDisclousure.onOpen()
        }}
      />
    </AwaitRender>
  )
}

// eslint-disable-next-line import/no-unused-modules
export default MainLayoutHeaderLegacy

function Links({
  isWhiteLabel,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  governanceSlug,
  ...linkProps
}: { governanceSlug: string; isWhiteLabel: boolean } & LinkProps) {
  const { onLittleDevice } = useDevice()

  return (
    <>
      {isWhiteLabel ? (
        <>
          {/* TODO(@nicolas): Quick fix to remove these links on ZKsync */}
          {/* <Link
            className="no-underline"
            color="neutral.600"
            fontWeight="medium"
            href={
              isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.claim.home(governanceSlug),
                  )
                : ROUTES.governance.claim.home(governanceSlug)
            }
            lineHeight="24px"
            {...linkProps}
          >
            Claim
          </Link> */}
          {/* <Link
            className="no-underline"
            color="neutral.600"
            fontWeight="medium"
            href={
              isWhiteLabel
                ? getWhiteLabelRoute(ROUTES.governance.faq(governanceSlug))
                : ROUTES.governance.faq(governanceSlug)
            }
            lineHeight="24px"
            {...linkProps}
          >
            FAQs
          </Link> */}
        </>
      ) : (
        <>
          <Link
            className="no-underline"
            color="neutral.600"
            data-qa="mainlayoutheader-btn-addadao"
            fontWeight="medium"
            href={ROUTES.getStarted.index()}
            lineHeight="24px"
            {...linkProps}
          >
            Add a DAO
          </Link>
          <Link
            className="no-underline"
            color="neutral.600"
            display={onLittleDevice}
            fontWeight="medium"
            href="#"
            id="open_web_chat"
            lineHeight="24px"
            {...linkProps}
          >
            Chat with Support
          </Link>
          <Link
            className="no-underline"
            color="neutral.600"
            fontWeight="medium"
            href={ROUTES.resources()}
            lineHeight="24px"
            {...linkProps}
          >
            Resources
          </Link>
          <Link
            className="no-underline"
            color="neutral.600"
            fontWeight="medium"
            href={ROUTES.media()}
            lineHeight="24px"
            {...linkProps}
          >
            Media
          </Link>
          <Link
            className="no-underline"
            color="neutral.600"
            fontWeight="medium"
            href={ROUTES.developers()}
            lineHeight="24px"
            {...linkProps}
          >
            Developers
          </Link>
        </>
      )}
    </>
  )
}

function UserLinks({
  account,
  governanceSlug,
  isWhiteLabel,
  accountId,
  ...linkProps
}: {
  governanceSlug: string
  isWhiteLabel: boolean
  account?: string
  accountId?: string
} & LinkProps) {
  return (
    <>
      {isWhiteLabel ? (
        <Link
          className="no-underline"
          color="neutral.600"
          fontSize="md"
          fontWeight="medium"
          href={getWhiteLabelRoute(
            ROUTES.governance.delegate.profile(
              governanceSlug,
              account as unknown as string,
            ),
          )}
          lineHeight="24px"
          {...linkProps}
        >
          <Stack isInline align="center" spacing={2}>
            <Icon as={User} h={4} w={4} />
            <Text>Delegate profile</Text>
          </Stack>
        </Link>
      ) : (
        <>
          <Link
            className="no-underline"
            color="neutral.600"
            fontSize="md"
            fontWeight="medium"
            href={ROUTES.user.yourDAOs()}
            lineHeight="24px"
            {...linkProps}
          >
            <Stack isInline align="center" spacing={2}>
              <Icon as={House} h={4} w={4} />
              <Text>Your DAOs</Text>
            </Stack>
          </Link>
          {account ? (
            <Link
              className="no-underline"
              color="neutral.600"
              fontSize="md"
              fontWeight="medium"
              href={ROUTES.profile(account)}
              lineHeight="24px"
              {...linkProps}
            >
              <Stack isInline align="center" spacing={2}>
                <Icon as={User} h={4} w={4} />
                <Text>Profile</Text>
              </Stack>
            </Link>
          ) : null}
          {!isWhiteLabel ? (
            <Link
              isExternal
              className="no-underline"
              color="neutral.600"
              fontSize="md"
              fontWeight="medium"
              href={EXTERNAL_ROUTES.notifications.index(accountId)}
              lineHeight="24px"
              {...linkProps}
            >
              <Stack isInline align="center" spacing={2}>
                <Icon as={Bell} h={4} w={4} />
                <Text>Notifications</Text>
              </Stack>
            </Link>
          ) : null}
        </>
      )}
      <Link
        className="no-underline"
        color="neutral.600"
        fontSize="md"
        fontWeight="medium"
        href={ROUTES.user.settings()}
        lineHeight="24px"
        {...linkProps}
      >
        <Stack isInline align="center" spacing={2}>
          <Icon as={Gear} h={4} w={4} />
          <Text>Settings</Text>
        </Stack>
      </Link>
      <SignInAsSafe
        governanceSlug={governanceSlug}
        isWhiteLabel={isWhiteLabel}
      />
    </>
  )
}

function HamburgerDrawer({
  finalRef,
  isOpen,
  onClose,
  onOpen,
  isWhiteLabel,
  displayConnectButtons,
  redirectTo,
  governanceSlug,
}: {
  finalRef: React.MutableRefObject<null>
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  isWhiteLabel: boolean
  displayConnectButtons: boolean
  redirectTo?: {
    login?: string
    logout?: string
    disconnect?: string
  }
  governanceSlug: string
}) {
  const { isLoggedIn } = useSession()
  const { address } = useAccount()
  const { logout } = useLogout()
  const me = useMe()

  return (
    <>
      <Drawer
        finalFocusRef={finalRef}
        isOpen={isOpen}
        placement="right"
        size="full"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent height="100% !important!">
          <DrawerHeader p={4}>
            <Flex align="center" direction="row" justify="flex-end" w="full">
              <IconButton
                _focusVisible={{
                  boxShadow: "none",
                  borderColor: "transparent",
                  outline: "3px solid hsla(216, 12%, 84%, 1)",
                  outlineOffset: "2px",
                }}
                aria-label="Close menu"
                bg="white"
                icon={<Icon as={X} fill="neutral.500" h={4} w={4} />}
                size="sm"
                onClick={onClose}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody
            borderTop="1px solid hsla(220, 17%, 93%, 1)"
            overflowY="auto"
            p={2}
            tabIndex={-1}
          >
            {displayConnectButtons ? (
              <Stack align="stretch">
                {address && isLoggedIn ? (
                  <>
                    <HeaderAddressButton
                      redirectTo={redirectTo?.disconnect}
                      signerAddress={address}
                      onOpen={onOpen}
                    />
                    <UserLinks
                      _hover={{
                        background: "neutral.100",
                      }}
                      account={address}
                      accountId={me?.id}
                      governanceSlug={governanceSlug}
                      isWhiteLabel={isWhiteLabel}
                      px={4}
                      py={2}
                      textAlign="start"
                    />
                    <Button
                      _focus={{
                        boxShadow: "none",
                        borderColor: "transparent",
                        outline: "3px solid hsla(216, 12%, 84%, 1)",
                        outlineOffset: "2px",
                      }}
                      _hover={{
                        background: "neutral.100",
                      }}
                      bg="white"
                      border="transparent.1"
                      color="neutral.600"
                      fontSize="md"
                      fontWeight="medium"
                      lineHeight="24px"
                      textAlign="start"
                      w="full"
                      onClick={() => logout(redirectTo?.logout)}
                    >
                      <Stack
                        isInline
                        align="center"
                        justify="flex-start"
                        spacing={2}
                        w="full"
                      >
                        <Icon as={RightFromBracket} h={4} w={4} />
                        <Text>Logout</Text>
                      </Stack>
                    </Button>
                  </>
                ) : (
                  <ConnectAccountButton
                    redirectTo={
                      isWhiteLabel
                        ? getWhiteLabelRoute(
                            ROUTES.governance.id(governanceSlug),
                          )
                        : ROUTES.user.yourDAOs()
                    }
                    variant="primary"
                    w="full"
                    onOpen={onOpen}
                  />
                )}
              </Stack>
            ) : null}
          </DrawerBody>
          <DrawerFooter
            alignItems="center"
            borderTop="1px solid hsla(220, 17%, 93%, 1)"
            justifyContent="center"
            p={2}
          >
            <Stack align="stretch" w="full">
              <Links
                _hover={{
                  background: "neutral.100",
                }}
                governanceSlug={governanceSlug}
                isWhiteLabel={isWhiteLabel}
                px={4}
                py={2}
                textAlign="start"
              />
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

function OwnedSafes({
  id,
  setLoadSafes,
}: {
  id: string
  setLoadSafes: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { data, isLoading } = useAddressSafesQuery({ accountId: id })
  const { route } = useRouter()
  const { loginAsSafe } = useLoginAsSafe()

  const handleLoginAsSafe = (safe: AccountId) => {
    setLoadSafes(false)
    const redirect =
      route === ROUTES.user.yourDAOs() ? undefined : ROUTES.user.yourDAOs()

    loginAsSafe(safe, redirect)
  }

  if (isLoading)
    return (
      <Stack>
        <Stack isInline align="center" spacing={3} w="full">
          <Spinner />
          <Text color="gray.600" textStyle="body.bold.sm">
            This may take a while
          </Text>
        </Stack>
      </Stack>
    )

  if (!data || !data.account.safes)
    return (
      <Stack>
        <Stack isInline align="center" spacing={3} w="full">
          <Text color="gray.600" textStyle="body.bold.sm">
            No Safes found
          </Text>
        </Stack>
      </Stack>
    )

  const { safes } = data?.account

  return (
    <Stack>
      {safes?.map((safe, index) => {
        const safeAddress = getAccountAddress(safe)
        const chain = findChainBySafeId(safe)
        const networkIcon = chain ? GenericNetworkIcon(chain) : undefined

        return (
          <Button
            key={`safe-${index}`}
            backgroundColor="white"
            h={10}
            onClick={() => handleLoginAsSafe(safe)}
          >
            <Stack isInline align="center" justify="left" spacing={3} w="full">
              {!!networkIcon ? (
                <Icon as={networkIcon} h={5} w={5} />
              ) : (
                <Icon as={Safe} h={5} w={5} />
              )}
              <Text color="gray.600" textStyle="body.bold.md">
                {shortenIfAddress(safeAddress)}
              </Text>
            </Stack>
          </Button>
        )
      })}
    </Stack>
  )
}

function SignInAsSafe({
  isWhiteLabel,
  governanceSlug,
}: {
  isWhiteLabel: boolean
  governanceSlug: string
}) {
  const me = useMe()
  const [loadSafes, setLoadSafes] = useState(false)
  const { signer, status } = useSignerStore()
  const { address } = useAccount()
  const { signInWithEthereum } = useSiwe()
  const connectorIcon = useConnectorIcon()

  const id = me?.id

  return (
    <Stack>
      {me?.type === "EOA" ? (
        <Button
          as={Button}
          backgroundColor="white"
          className="no-underline"
          color="neutral.600"
          fontSize="16px"
          fontWeight="medium"
          justifyContent="start"
          lineHeight="24px"
          textAlign="left"
          onClick={() => setLoadSafes(true)}
        >
          <Stack isInline align="center" spacing={2}>
            <Icon as={Safe} h={4} w={4} />
            <Text>Sign in as Safe</Text>
          </Stack>
        </Button>
      ) : null}
      {me?.type === "SAFE" && address && status === "success" && signer ? (
        <Button
          as={Button}
          backgroundColor="white"
          className="no-underline"
          justifyContent="start"
          textAlign="left"
          onClick={() =>
            signInWithEthereum({
              signer,
              address,
              redirectTo: isWhiteLabel
                ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
                : ROUTES.user.yourDAOs(),
            })
          }
        >
          <Stack isInline align="center" spacing={2} w="full">
            <Icon as={Wallet} h={4} w={4} />
            <Stack align="center" direction="row" spacing={2}>
              <Text
                color="neutral.600"
                fontSize="md"
                fontWeight="medium"
                lineHeight="24px"
              >
                Switch to
              </Text>
              <Stack align="center" direction="row" rounded="md">
                <Icon as={connectorIcon} color="gray.600" h={4} w={4} />
                <Text
                  color="neutral.600"
                  fontSize="md"
                  fontWeight="medium"
                  lineHeight="24px"
                >
                  {shortString(address)}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Button>
      ) : null}
      {id && loadSafes ? (
        <OwnedSafes id={id} setLoadSafes={setLoadSafes} />
      ) : (
        <></>
      )}
    </Stack>
  )
}
